// app/javascript/packs/controllers/modal_info_controller.js
import { Controller } from 'stimulus';
import $ from 'jquery';

export default class ModalInfoController extends Controller {
    static targets = ['modalOption', 'saveButton']

    initialize() {
        this.$modalOption = $(this.modalOptionTarget);
        this.$modalOptionBackdrop = $('.general-backdrop');
    }

    showModal() {
        const modalElement = this.element.querySelector('.modal.fade.general-info-dialog');
        if (modalElement) {
            $(modalElement).addClass('show').show();
            this.$modalOptionBackdrop.show();
        } else {
            this.$modalOption.addClass('show').show();
            this.$modalOptionBackdrop.show();
        }
    }

    isModalDisplay() {
        const modalElement = this.element.querySelector('.modal.fade.general-info-dialog');
        const isDisplayed = modalElement ? !$(modalElement).is(':hidden') : !this.$modalOption.is(':hidden');
        return isDisplayed;
    }

    hideModal(e) {
        const modalElement = this.element.querySelector('.modal.fade.general-info-dialog');
        if (modalElement) {
            $(modalElement).removeClass('show').hide();
            this.$modalOptionBackdrop.hide();
        } else {
            this.$modalOption.removeClass('show').hide();
            this.$modalOptionBackdrop.hide();
        }

        const button = document.querySelector('.nm-btn.export-btn');
          if (button) {
            button.disabled = true;
            const tooltip = button.parentElement.querySelector('.nm-tooltip');
            if (tooltip) {
              const tooltipInner = tooltip.querySelector('.tooltip-inner');
              if (tooltipInner) {
                tooltipInner.textContent = 'רשימת התלמידים בתהליך הפקה';
              }
            }
          }
    }

    outsideClick(e) {
        if (e.target === e.currentTarget) {
            this.hideModal(e);
        }
    }
}